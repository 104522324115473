import {Injectable} from "@angular/core";
import {UserBasicInfo} from "../model/user";
import {UserService} from "./api/user.service";
import {BehaviorSubject, Observable, Subject, tap} from "rxjs";
import {AuthResponse, UserPersonalResponse} from "../model/responses";
import {NavigationService} from "./ui/navigation.service";

@Injectable()
export class AuthedUserService {

    LOCAL_STORAGE_KEY = 'currentUser';

    private authedUser?: AuthedUser;
    private authedUserSubject = new BehaviorSubject<AuthedUser | undefined>(undefined);

    constructor(
        private userService: UserService,
        private navigationService: NavigationService
    ) {
    }

    removeAuthInfo() {
        console.log("Revoking auth info");
        this.authedUser = undefined;
        localStorage.removeItem(this.LOCAL_STORAGE_KEY);
        this.authedUserSubject.next(undefined);
    }

    setAuth(auth: AuthResponse): Observable<UserBasicInfo> {
        this.authedUser = {auth: auth, userData: this.authedUser?.userData};
        const meRetrieval = this.userService.getMe().pipe(
            tap({
                next: userData => {
                    this.authedUser = {auth: auth, userData: userData};
                    localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(this.authedUser));
                    this.authedUserSubject.next(this.authedUser);
                },
                error: error => {
                    console.log("Error retrieving user data: ", error);
                    this.removeAuthInfo()
                }
            })
        );

        if ((window as any).Android && (window as any).Android.setToken) {
            (window as any).Android.setToken(auth.token.token)
        }

        // Ensure that the user data is always retrieved and the token is set
        meRetrieval.subscribe()

        return meRetrieval
    }

    assertAuthedUser() {
        if (!this.getAuthedUser()) {
            //this.navigationService.navigate(['/login'], true, {
            //    queryParams: {redirect: decodeURIComponent(this.navigationService.currentPath)}
            //});
            this.navigationService.navigate(['/'], true);
        }
    }

    getAuthedUser(): AuthedUser | undefined {
        if (!this.authedUser) {
            const authedUser = localStorage.getItem(this.LOCAL_STORAGE_KEY);
            if (authedUser) {
                this.authedUser = JSON.parse(authedUser);
                this.authedUserSubject.next(this.authedUser);

                if (this.authedUser?.auth && (window as any).Android && (window as any).Android.setToken) {
                    (window as any).Android.setToken(this.authedUser.auth.token.token)
                }
            }
        }
        return this.authedUser;
    }

    getAuthedUserObservable(): Observable<AuthedUser | undefined> {
        return this.authedUserSubject.asObservable();
    }

    getAuth(): AuthResponse | undefined {
        return this.getAuthedUser()?.auth || undefined;
    }

    getUserData(): UserPersonalResponse | undefined {
        return this.getAuthedUser()?.userData || undefined;
    }

    getUserId(): string | undefined {
        return this.getUserData()?.id || undefined;
    }

    getUsername(): string | undefined {
        return this.getUserData()?.username || undefined;
    }

    // Needed for when the user object is changed (e.g. username)
    save() {
        if (this.authedUser) {
            localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(this.authedUser));
        }
    }
}

export type AuthedUser = {
    auth?: AuthResponse,
    userData?: UserPersonalResponse
};
