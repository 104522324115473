// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    apiUrl: 'https://api.test.ludoya.com',
    oauthClientId: '483235950245-ikggh0a06kon3h6dr9khksjo12u4pf0r.apps.googleusercontent.com',
    mapsApiKey: 'AIzaSyAYVfrDhz_V-xAKWQw3D9M1-7ph76r6y-c',

    version: '0.19.2'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
