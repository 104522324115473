import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {
    ChangePasswordRequest,
    GoogleLoginRequest,
    LoginRequest,
    RecoverPasswordRequest,
    RegisterRequest,
    ResetPasswordRequest,
    SetPasswordRequest,
    UpdateUserGeoLocationRequest, UserDeleteRequest,
    UsernameUpdateRequest,
    UserPrivacySettingsRequest,
    UserUpdateRequest,
    VerifyEmailRequest
} from "../../model/requests";
import {
    AuthResponse,
    AvatarUploadResponse,
    UserCheckResponse,
    UserFavState,
    UserNotificationSettings,
    UserPersonalResponse,
    UserResponse
} from "../../model/responses";
import {environment} from "../../../environments/environment";
import {Visibility} from "../../model/visibility";

@Injectable()
export class UserService {

    constructor(
        private http: HttpClient
    ) {
    }

    login(request: LoginRequest): Observable<AuthResponse> {
        return this.http.post<AuthResponse>(`${environment.apiUrl}/users/login`, request);
    }

    googleLogin(request: GoogleLoginRequest): Observable<AuthResponse> {
        return this.http.post<AuthResponse>(`${environment.apiUrl}/users/login/google`, request)
    }

    registerUser(request: RegisterRequest): Observable<UserPersonalResponse> {
        return this.http.post<UserPersonalResponse>(`${environment.apiUrl}/users/register`, request);
    }

    checkUsernameAvailability(username: string): Observable<UserCheckResponse> {
        return this.http.get<UserCheckResponse>(`${environment.apiUrl}/users/check/username/${username}`);
    }

    checkEmailAvailability(email: string): Observable<UserCheckResponse> {
        return this.http.get<UserCheckResponse>(`${environment.apiUrl}/users/check/email/${email}`);
    }

    verifyEmail(request: VerifyEmailRequest): Observable<AuthResponse> {
        return this.http.post<AuthResponse>(`${environment.apiUrl}/users/verify-email`, request);
    }

    refreshAuth(refreshToken: string): Observable<AuthResponse> {
        return this.http.get<AuthResponse>(`${environment.apiUrl}/users/login/refresh`, {
            headers: {
                'Authorization': `Bearer ${refreshToken}`
            }
        });
    }

    setPassword(request: SetPasswordRequest): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/users/password/set`, request);
    }

    recoverPassword(request: RecoverPasswordRequest): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/users/password/recover`, request);
    }

    resetPassword(request: ResetPasswordRequest): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/users/password/reset`, request);
    }

    changePassword(request: ChangePasswordRequest): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/users/password/change`, request);
    }

    getUser(username: string): Observable<UserResponse> {
        return this.http.get<UserResponse>(`${environment.apiUrl}/users/${username}`);
    }

    getMe(): Observable<UserPersonalResponse> {
        return this.http.get<UserPersonalResponse>(`${environment.apiUrl}/users/me`);
    }

    updateUser(request: UserUpdateRequest): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/users/me`, request);
    }

    deleteAccount(request: UserDeleteRequest): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/users/me/delete`, request);
    }

    setUserFirstLogin(): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/users/me/first-login`, {});
    }

    updateUsername(request: UsernameUpdateRequest): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/users/me/username`, request);
    }

    updateUserGeoLocation(request: UpdateUserGeoLocationRequest): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/users/me/geolocation`, request);
    }

    getNotificationSettings(): Observable<UserNotificationSettings> {
        return this.http.get<UserNotificationSettings>(`${environment.apiUrl}/users/me/notification-settings`);
    }

    updateNotificationSettings(settings: UserNotificationSettings): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/users/me/notification-settings`, settings);
    }

    updatePrivacySettings(settings: UserPrivacySettingsRequest): Observable<any> {
        return this.http.put<any>(`${environment.apiUrl}/users/me/privacy-settings`, settings);
    }

    updateAvatar(avatar: Blob): Observable<AvatarUploadResponse> {
        const formData = new FormData();
        formData.append('avatar', avatar);
        return this.http.put<AvatarUploadResponse>(`${environment.apiUrl}/users/me/avatar`, formData);
    }
}

export function isUserVisible(user: UserResponse, currentUserId?: string): boolean {
    if (user.favState == UserFavState.BLOCKED) {
        return false;
    }
    return user.visibility == Visibility.PUBLIC || (currentUserId && user.id == currentUserId) ||
        (user.favState == UserFavState.RECIPROCAL && user.visibility == Visibility.PROTECTED)
}
