
export enum Visibility {
    PUBLIC = "PUBLIC",
    PROTECTED = "PROTECTED",
    PRIVATE = "PRIVATE",
}

export const visibilities = [
    {
        type: Visibility.PUBLIC,
        visibilityLabel: $localize`Anyone`,
        messagingLabel: $localize`Anyone`,
        meetupLabel: $localize`Public`
    },
    {
        type: Visibility.PROTECTED,
        visibilityLabel: $localize`Friends`,
        messagingLabel: $localize`Friends`,
        meetupLabel: $localize`Only Friends`
    },
    {
        type: Visibility.PRIVATE,
        visibilityLabel: $localize`Only Me`,
        messagingLabel: $localize`Nobody`,
        meetupLabel: $localize`Only Me`
    }
]
