import {Injectable} from '@angular/core';
import {Location} from "@angular/common";
import {NavigationEnd, NavigationExtras, Params, Router, Scroll} from "@angular/router";
import {filter} from "rxjs";
import {CurrentViewDataService} from "../current-view-data.service";

//declare let gtag: Function;

@Injectable()
export class NavigationService {

    private history: string[] = []
    private scrollPositionHistory: number[] = []

    private currentPageScrollPosition: number = 0

    constructor(
        private location: Location,
        private router: Router,
        private currentViewDataService: CurrentViewDataService
    ) {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(event => {
            if ((window as any).Android && (window as any).Android.setRoute) {
                (window as any).Android.setRoute((event as NavigationEnd).urlAfterRedirects)
            }
            //gtag('config', 'G-NKWC1LE6CV', {'page_path': (event as NavigationEnd).urlAfterRedirects});
        });

        const history = localStorage.getItem('history')
        if (history) {
            this.history = JSON.parse(history)
        }
        const scrollPositionHistory = localStorage.getItem('scrollPositionHistory')
        if (scrollPositionHistory) {
            this.scrollPositionHistory = JSON.parse(scrollPositionHistory)
        }
    }

    public get currentPath(): string {
        return this.location.path()
    }

    public canGoBack(): boolean {
        return this.history.length > 0 ||
            this.currentViewDataService.selectedDrawerGame != undefined
    }

    public goBack(): string {
        if (this.currentViewDataService.selectedDrawerGame != undefined) {
            this.currentViewDataService.selectGame(undefined, true)
            return 'closed drawer'
        }

        this.currentPageScrollPosition = 0
        if (this.history.length > 0) {
            let backUrl = this.history.pop()
            let backExtra: Params | undefined = undefined
            if (backUrl?.includes('?')) {
                const parts = backUrl.split('?')
                backUrl = parts[0]
                backExtra = {}
                parts[1].split('&').forEach(part => {
                    const pair = part.split('=')
                    backExtra![pair[0]] = pair[1]
                })
            }
            this.router.navigate(backUrl ? [backUrl] : ['/'], backExtra ? {queryParams: backExtra} : undefined
            ).then(success => {
                console.log('navigated back to ' + backUrl + ' success: ' + success)
            }).catch(error => {
                console.error('Error navigating back: ' + error)
            })
            //this.location.go(backUrl || '/')
            const scrollPosition = this.scrollPositionHistory.pop()
            if (scrollPosition) {
                setTimeout(() => {
                    window.scrollTo(0, scrollPosition)
                }, 600)
                this.currentPageScrollPosition = scrollPosition
            }
            this.saveHistory()
            console.log('back: ' + this.history)
            return backUrl || '/'
        } else {
            console.log('back: ' + this.history)
            this.router.navigate(['/']).then(success => {
                console.log('navigated back to / success: ' + success)
            }).catch((error) => {
                console.error('Error navigating back: ' + error)
            })
            //this.location.go('/')
            return '/'
        }
    }

    public onBackNavigation(): void {
        this.currentPageScrollPosition = 0
        this.history.pop()
        const scrollPosition = this.scrollPositionHistory.pop()
        this.saveHistory()
        if (scrollPosition) {
            setTimeout(() => {
                window.scrollTo(0, scrollPosition)
            }, 300)
            this.currentPageScrollPosition = scrollPosition
        }
    }

    public navigateBack(commands: any[], isRoot: boolean = false): Promise<boolean> {
        this.onBackNavigation()

        this.saveHistory()

        console.log('back: ' + this.history)
        //this.location.go(commands[0])
        return this.router.navigate(commands)
    }

    public navigate(commands: any[], isRoot: boolean = false, extras?: NavigationExtras): Promise<boolean> {
        if (this.currentViewDataService.selectedDrawerGame != undefined) {
            this.currentViewDataService.selectGame(undefined, true)
        }

        this.history.push(this.location.path())
        const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
        this.scrollPositionHistory.push(currentScrollTop)
        this.currentPageScrollPosition = 0

        this.saveHistory()

        console.log('forward: ' + this.history.join('|'))
        return this.router.navigate(commands, extras)
    }

    public resetHistory(): void {
        this.history = []
        this.scrollPositionHistory = []
    }

    public updateScrollPosition(): void {
        window.scrollTo(0, this.currentPageScrollPosition)
        setTimeout(() => {
            this.currentPageScrollPosition = 0
        }, 1000)
    }

    saveHistory(): void {
        this.history = this.history.slice(-10)
        this.scrollPositionHistory = this.scrollPositionHistory.slice(-10)
        localStorage.setItem('history', JSON.stringify(this.history))
        localStorage.setItem('scrollPositionHistory', JSON.stringify(this.scrollPositionHistory))
    }

    public openExternalUrl(url: string) {
        console.log('openExternalUrl: ' + url)
        if ((window as any).Android && (window as any).Android.openExternalLink) {
            (window as any).Android.openExternalLink(url)
            console.log('openExternalLink in android')
        }
    }
}
